* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;  
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: inherit;
}

img {
  width: 100%;
  height: auto;
}

main {
  overflow-x: hidden;
}

.section {
  max-width: 100vw;
  max-height: 100vh;
}

.section-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
