.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin: 60px auto;
}

.contact-result {
  display: none;
  justify-content: center;
  align-content: center;
  padding: 10px;
  border-radius: 1.5px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.contact-result.success {
  display: flex;
  background: #08aeac;
}

.contact-result.error {
  display: flex;
  background: #f95959;
}

.contact-form {
  position: relative;
  height: 50%;
  width: 50%;
  max-width: 32rem;
  margin: 10px auto;
  padding: 0 20px;
}

.validator-error {
  margin: 5px 10px;
  font-size: 12px;
}

.form-group {
  margin-bottom: 8px;
}

.form-control {
  display: block;
  height: 40px;
  width: 100%;
  margin: 15px 0;
  padding: 1.5rem;
  color: #555;
  background-color: hsla(208, 50%, 50%, 6.5%);
  border: 2px solid transparent;
  outline: none;
  border-radius: 20px;
  line-height: 1.42857143;
  font-size: 14px;
  font-weight: 400;
  transition: .3s;
}

.form-control:hover {
  background-color: hsla(208, 50%, 50%, 14%);
}

.form-control:focus {
  background-color: #fff;
  border: 2px solid hsl(208, 91%, 55%);
  box-shadow: 0 0 0 5px hsla(208, 91%, 55%, 0.11);
}

textarea.form-control {
  min-height: 100px;
  min-width: 100%;
  max-width: 100%;
}

.contact-subtitle {
  text-align: center;
  font-size: 2rem;
  border: black;
  border-radius: 25%;
}

.form-send {
  display: inline-block;
  width: 100%;
  padding: 1.1rem 2rem;
  background-color: hsl(208, 92%, 54%);
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  transition: .3s;
}

.form-send:hover {
  background-color: hsl(208, 77%, 48%);
}

@media screen and (max-width: 768px) {
  .contact {
    margin: 40px 20px 0;
  }

  .contact-form {
    height: 50%;
    padding: 0 20px;
    position: flex;
    width: 100%;
    margin: 10px auto;
  }

  textarea.form-control {
    min-height: inherit;
  }
}
