.slider {
  display: flex;
  align-items: center;
  max-width: 100vw;
  max-height: 100vh;
  padding: 5em;
  opacity: 0;
  transform: scale(85%);
  transition: 2s;
}

.slider--zoom {
  opacity: 1;
  transform: scale(100%);
  transition: 2s;
}

.slider_image {
  width: 50%;
}

.slider_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
  margin: 2em;
  text-align: left;
  font-size: 120%;
}

.slider_content h3,
.slider_content p {
  margin: 1em 0;
}

.slider_content p {
  text-align: justify;
}

.slider_title {
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .slider {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    padding: 0 1em;
  }

  .slider_image{
    width: 100%;
    height: auto;
  }

  .slider_content {
    margin: 1em;
    padding: 0;
    font-size: 90%;
  }
}
