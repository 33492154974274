.navbar {
  position: fixed;
  top: 0;
  height: 80px;
  width: 100vw;
  background-color: hsl(208, 60%, 30%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  z-index: 5;
}

.navbar.navbar--open {
  height: 100vh;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.navbar.navbar.navbar--open .navbar_logo {
  display: none;
}

.navbar.navbar.navbar--open .navbar_menu {
  position: absolute;
  top: 0;
  right: 0;
}

.navbar.navbar.navbar--open .navbar_sidebar_menu_list_item { 
  text-align: center;
}

.navbar_menu,
.navbar_sidebar_menu {
  display: none;
}

.navbar_menu {
  flex: 0;
  padding: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.navbar_sidebar_menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar_sidebar_menu_list {
  list-style: none;
}

.navbar_sidebar_menu_list_item {
  margin: 40px 0;
  font-size: 2rem;
  text-align: left;
}

.navbar_sidebar_menu_list_item a {
  color: #FFF;
  background: none;
  border: none;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
}

.navbar_logo {
  height: 64px;
  width: auto;
  cursor: pointer;
}

.navbar_list {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  list-style: none;
}

.navbar_item {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.navbar_link {
  padding: 10px;
  margin: 0px 5px;
  font-size: 1rem;
  background: none;
  color: #fff;
  text-align: center;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: transparent;
  transition: 250ms;
  font-family: 'Electrolize', sans-serif;
  cursor: pointer;
}

.navbar_link:hover {
  border-color: #fff;
  transition: 250ms;
}

@media screen and (max-width: 768px) {
  .navbar_menu {
    display: inherit;
  }

  .navbar_list {
    display: none;
  }

  .navbar_logo {
    height: 62px;
  }
}