footer {
  padding: 60px;
  background: hsl(208, 60%, 30%);
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
}

.footer-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.footer-heading {
  font-size: 1.6rem;
  color: #5195d0;
  margin-bottom: 1rem;
  font-family: "Electrolize", sans-serif;
  font-weight: bold;
}

.footer-link {
  font-family: "Electrolize", sans-serif;
  font-size: 1rem;
  color: #fff;
  text-decoration: none;
}

.footer-link:hover {
  color: black;
  transition: 200ms ease-in;
}

.footer-logo img {
  height: 80%;
  width: 80%;
}

button.move-to-top {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  width: 3rem;
  height: 3rem;
  background-color: hsl(208, 92%, 54%);
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: .3s;
}

button.move-to-top:hover {
  background-color: hsl(208, 77%, 48%);
}

@media screen and (max-width: 768px) {
  .footer_logo_container,
  .footer_logo {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  footer {
    padding: 70px 30px;
  }

  .footer-row {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
