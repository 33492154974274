.hero_image {
    height: 100vh;
    width: auto;
    min-width: 100vw;
    object-fit: cover;
}

.hero_title {
    position: absolute;
    bottom: 18%;
    width: 100%;
    padding: 15px;
    color: #FFF;
    font-size: 4rem;
    text-align: center;
    border-color: black;
    font-family: 'Electrolize', sans-serif;
}

.hero_title2 {
    position: absolute;
    top: 78%;
    width: 100%;
    padding: 15px;
    color: #fff;
    font-size: 4rem;
    text-align: center;
    border-color: black;
    border-width: 1px;
    font-family: 'Electrolize', sans-serif;
}

@media screen and (max-width: 768px) {
    .hero_title  {
        position: absolute;
        bottom: 20%;
        width: 100%;
        padding: 15px;
        color: #fff;
        font-size: 2.5rem;
        text-align: center;
        border-color: black;
        font-family: 'Electrolize', sans-serif;
    }  

    .hero_title2  {
        position: absolute;
        bottom: 80%;
        width: 100%;
        padding: 15px;
        color: #fff;
        font-size: 2.5rem;
        text-align: center;
        border-color: black;
        font-family: 'Electrolize', sans-serif;
    }  
}
